import AddIcon from '@mui/icons-material/Add'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {Box, IconButton, Paper, Typography} from '@mui/material'
import {gridClasses} from '@mui/x-data-grid'
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel
} from '@mui/x-data-grid-pro'
import {isNumber} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  DiscountCodeState,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useTranslateDiscountCodeState} from '../../../../../hooks/discountCodeState'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useEnsurePermissions} from '../../../../../utils/auth'

import {EntityStateChip, Tooltip} from '../../../../common'
import {ConfirmationDialog} from '../../../../common/ConfirmationDialog'
import {
  DataGridTable,
  useDateTimeFormatter,
  useMadeByNameFormatter
} from '../../../../common/DataGridTable'
import {discountCodeStateColors} from '../../../../constants'
import {DropdownButton} from '../../../../visual/DropdownButton'
import {useDiscountCodes, useInvalidateDiscountCodes} from '../graphql'
import {
  CreateDiscountCodeDrawer,
  CreateDiscountCodesDrawer
} from './CreateDiscountCodeDrawer'
import {DataGridPagination} from './DataGridPagination'
import {DataGridToolbar} from './DataGridToolbar'

declare module '@mui/x-data-grid-pro' {
  interface PaginationPropsOverrides {
    selectedItemIds: number[]
  }
}

const IconCellRenderer = ({
  id,
  state
}: {
  id: number
  state: DiscountCodeState
}) => {
  const {t} = useTranslation()
  const invalidateDiscountCode = useInvalidateDiscountCodes()
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const {
    state: isOpen,
    setTrue: setOpened,
    setFalse: setClosed
  } = useBooleanState(false)
  const handleInvalidateConfirmed = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await invalidateDiscountCode([id])
      setClosed()
    } catch (e) {
      defaultErrorHandler(e, t('Error while invalidating discount code'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    setShowBackdrop,
    invalidateDiscountCode,
    id,
    setClosed,
    defaultErrorHandler,
    t
  ])
  return state !== DiscountCodeState.Invalid ? (
    <>
      <Tooltip title={t('Invalidate')}>
        <IconButton onClick={setOpened}>
          <HighlightOffIcon />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        title={t('Invalidate discount code?')}
        contentText={t(
          "Are you sure you want to invalidate discount code? This can't be undone."
        )}
        confirmButtonLabel={t('Invalidate')}
        isOpen={isOpen}
        onCancel={setClosed}
        onConfirm={handleInvalidateConfirmed}
      />
    </>
  ) : null
}

const StateRenderer = ({state}: {state: DiscountCodeState}) => {
  const translateDiscountCodeState = useTranslateDiscountCodeState()
  return (
    <EntityStateChip
      label={translateDiscountCodeState(state)}
      colorConf={discountCodeStateColors[state]}
      isDotHidden
    />
  )
}

interface ICodesBlockProps {
  label: string
  blockId: string
  discountId: number
}

export const CodesBlock: React.FC<ICodesBlockProps> = ({
  label,
  blockId,
  discountId
}: ICodesBlockProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [discountCodeSearch, setDiscountCodeSearch] =
    useState<string | undefined>(undefined)
  const {data, loading, refetch} = useDiscountCodes(discountId, {
    name: discountCodeSearch
  })
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([])
  const {
    state: isCreateDiscountCodeDrawerOpen,
    setTrue: openDiscountCodeDrawer,
    setFalse: closeDiscountCodeDrawer
  } = useBooleanState(false)
  const {
    state: isCreateDiscountCodesDrawerOpen,
    setTrue: openDiscountCodesDrawer,
    setFalse: closeDiscountCodesDrawer
  } = useBooleanState(false)
  const translateDiscountCodeState = useTranslateDiscountCodeState()
  const dateTimeFormatter = useDateTimeFormatter()
  const madeByNameFormatter = useMadeByNameFormatter(true)
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Code'),
        field: 'name',
        minWidth: 150
      },
      {
        headerName: t('Available'),
        field: 'remainingRedemptionCount',
        valueGetter: (params) =>
          params.value === null ? Number.POSITIVE_INFINITY : params.value,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <>
              {params.value === Number.POSITIVE_INFINITY ? '∞' : params.value}
            </>
          )
        },
        valueFormatter: (params) =>
          params.value === Number.POSITIVE_INFINITY ? '' : params.value,
        align: 'right',
        headerAlign: 'right',
        minWidth: 100
      },
      {
        headerName: t('Usage'),
        field: 'usageCount',
        align: 'right',
        headerAlign: 'right',
        minWidth: 100
      },
      {
        headerName: t('Redemption intent'),
        field: 'redemptionIntentCount',
        align: 'right',
        headerAlign: 'right',
        minWidth: 150
      },
      {
        headerName: t('Limit'),
        field: 'usageLimit',
        valueGetter: (params) =>
          params.value === null ? Number.POSITIVE_INFINITY : params.value,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <>
              {params.value === Number.POSITIVE_INFINITY ? '∞' : params.value}
            </>
          )
        },
        valueFormatter: (params) =>
          params.value === Number.POSITIVE_INFINITY ? '' : params.value,
        align: 'right',
        headerAlign: 'right',
        minWidth: 100
      },
      {
        headerName: t('Limit per order'),
        field: 'usageLimitPerOrder',
        valueGetter: (params) =>
          params.value === null ? Number.POSITIVE_INFINITY : params.value,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <>
              {params.value === Number.POSITIVE_INFINITY ? '∞' : params.value}
            </>
          )
        },
        valueFormatter: (params) =>
          params.value === Number.POSITIVE_INFINITY ? '' : params.value,
        align: 'right',
        headerAlign: 'right',
        minWidth: 120
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: DiscountCodeState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        valueFormatter: (params) => translateDiscountCodeState(params.value),
        minWidth: 50
      },
      {
        headerName: t('Activation'),
        field: 'activationDate',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Expires'),
        field: 'expirationDate',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Description'),
        field: 'description',
        minWidth: 300
      },
      {
        headerName: t('Created by'),
        field: 'createdByName',
        valueFormatter: madeByNameFormatter,
        minWidth: 200
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Updated by'),
        field: 'updatedByName',
        valueFormatter: madeByNameFormatter,
        minWidth: 200
      },
      {
        headerName: t('Updated at'),
        field: 'updatedAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} state={params.row.state} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48,
        disableExport: true
      }
    ],
    [dateTimeFormatter, madeByNameFormatter, t, translateDiscountCodeState]
  )

  const getDropdownButtonItems = () => {
    const dropdownButtonItems = []

    P([PermissionCode.CreateDiscountCode]) &&
      dropdownButtonItems.push({
        onClick: openDiscountCodeDrawer,
        children: t('One code')
      })

    P([PermissionCode.CreateDiscountCodes]) &&
      dropdownButtonItems.push({
        onClick: openDiscountCodesDrawer,
        children: t('Multiple codes')
      })
    return dropdownButtonItems
  }
  return (
    <>
      <Box id={blockId}>
        <Box
          sx={{
            py: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="subtitle1">{label}</Typography>
          {(P([PermissionCode.CreateDiscountCode]) ||
            P([PermissionCode.CreateDiscountCodes])) && (
            <DropdownButton
              sx={{position: 'relative', zIndex: 1}}
              buttonProps={{
                variant: 'outlined',
                children: (
                  <>
                    <AddIcon fontSize="small" />
                    &nbsp; {t('Create code')}
                  </>
                )
              }}
              options={getDropdownButtonItems()}
            />
          )}
        </Box>
        <Paper variant="outlined" sx={{py: 1.5}}>
          <Box sx={{pb: 2, px: 3}}>
            <Typography variant="subtitle2">
              {t('Discount can be used only with discount codes')}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {t(
                'Discount is not listed in discounts offer for customer or cashier, but is available only after code validation.'
              )}
            </Typography>
          </Box>
          <DataGridTable
            sx={{
              [`.${gridClasses.main}`]: {
                overflow: 'unset'
              },
              [`.${gridClasses.columnHeaders}`]: {
                position: 'sticky',
                top: 0,
                backgroundColor: 'background.paper',
                zIndex: 1
              },
              border: 'none',
              borderRadius: 0,
              [`.${gridClasses.footerContainer}`]: {
                justifyContent: 'flex-start'
              }
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel)
            }}
            rowSelectionModel={rowSelectionModel}
            slots={{
              toolbar: DataGridToolbar,
              pagination: DataGridPagination
            }}
            slotProps={{
              toolbar: {
                selectedItemIds: rowSelectionModel.filter(isNumber),
                setSelectedItemIds: setRowSelectionModel,
                onDiscountCodeSearchChange: setDiscountCodeSearch
              },
              pagination: {
                selectedItemIds: rowSelectionModel.filter(isNumber)
              }
            }}
            columns={columns}
            rows={data?.discountCodes || []}
            pageSizeOptions={[10, 30, 50]}
            autoHeight
            disableRowSelectionOnClick
            disableMultipleRowSelection
            checkboxSelection
            hideFooterSelectedRowCount
            disableColumnFilter
            initialState={{
              pagination: {paginationModel: {pageSize: 30}},
              pinnedColumns: {
                left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'name'],
                right: ['id']
              }
            }}
            columnVisibilityModel={{
              id: P([PermissionCode.InvalidateDiscountCodes])
            }}
            loading={loading}
            localeText={{noRowsLabel: t('No codes to show')}}
          />
        </Paper>
      </Box>
      <CreateDiscountCodeDrawer
        isOpen={isCreateDiscountCodeDrawerOpen}
        onClose={closeDiscountCodeDrawer}
        discountId={discountId}
        refetch={refetch}
      />
      <CreateDiscountCodesDrawer
        isOpen={isCreateDiscountCodesDrawerOpen}
        onClose={closeDiscountCodesDrawer}
        discountId={discountId}
        refetch={refetch}
      />
    </>
  )
}
