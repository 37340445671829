import {useLazyQuery} from '@apollo/react-hooks'
import CancelIcon from '@mui/icons-material/Cancel'
import {Button, Chip, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {ApolloError} from 'apollo-client'
import {difference, isNil} from 'lodash'
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  BroadDiscountCodePropertiesFragment,
  CheckDiscountCodeForCartQuery,
  CheckDiscountCodeForCartQueryVariables,
  CheckDiscountCodeForReservationQuery,
  CheckDiscountCodeForReservationQueryVariables,
  DiscountApplication,
  DiscountCodeQuery,
  DiscountCodeQueryVariables,
  DiscountCodeState,
  EnabledDiscountPropertiesFragment,
  PermissionCode,
  TicketItemPropertiesFragment
} from '../../../../../__generated__/schema'
import {useFormatDiscountValue} from '../../../../../hooks/formatDiscountValue'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useReservationPathnameParams} from '../../../../../utils/pathname'
import {Tooltip} from '../../../../common'
import {ListOfItemsSeparatedByDividers} from '../../../../common/ListOfItemsSeparatedByDividers'
import {MassCheckbox, MassCheckboxState} from '../../../../common/MassCheckbox'
import {QuantityController} from '../../../../common/QuantityController'
import {getTicketListItemPrimaryLabel} from '../../components/BasicTicketListItem'
import {TicketsSubtotal} from '../../components/TicketsSubtotal'
import {CheckDiscountCodeItem} from '../CheckDiscountCodeItem'
import {useCurrentCart} from '../CurrentCartContext'
import {DiscountCodeErrorDialog} from '../DiscountCodeErrorDialog'
import {DiscountListItem} from '../DiscountListItem'
import {EditDiscountQuantityDialog} from '../EditDiscountQuantityDialog'
import {
  CHECK_DISCOUNT_CODE_FOR_CART,
  CHECK_DISCOUNT_CODE_FOR_RESERVATION,
  DISCOUNT_CODE
} from '../graphql'
import {SelectCustomerDialog} from '../SelectCustomerDialog'
import {
  defaultSelectCustomerDialogState,
  ISelectCustomerDialogState,
  ISelectCustomerForm,
  SelectCustomerFormField
} from '../types'
import {
  getIsIncrementButtonDisabled,
  getMaxQuantityOfItemsForDiscount,
  getMaxQuantityOfItemsForDiscountCode,
  getQuantityOfItemsWithAppliedDiscount,
  getQuantityOfItemsWithAppliedDiscountCode,
  getRemainingDiscountCodeUsageLimitPerOrder,
  getRemainingDiscountUsageLimitPerOrder,
  getRemainingDiscountUsageLimitPerOrderForDiscountDialog
} from '../utils'
import {Item} from './Item'
import {NoDiscountableItemsBlank} from './NoDiscountableItemsBlank'

interface IContentProps {
  ticketItems: TicketItemPropertiesFragment[]
  discounts: EnabledDiscountPropertiesFragment[]
  eventId: number
  onAddButtonClick?: ({
    discountId,
    selectedItemIds,
    discountCodeId,
    customerLoyaltyId,
    onSuccessCallback
  }: {
    discountId: number
    selectedItemIds: number[]
    discountCodeId?: number
    customerLoyaltyId?: string
    onSuccessCallback?: () => void
  }) => Promise<void>
  incrementDiscountQuantity?: ({
    discountId,
    discountCodeId,
    increment,
    notificationOnSuccess,
    customerLoyaltyId,
    onSuccessCallback
  }: {
    discountId: number
    discountCodeId?: number
    increment?: number
    notificationOnSuccess?: string
    customerLoyaltyId?: string
    onSuccessCallback?: () => void
  }) => Promise<void>
  decrementDiscountQuantity?: ({
    discountId,
    discountCodeId,
    decrement,
    notificationOnSuccess
  }: {
    discountId: number
    discountCodeId?: number
    decrement?: number
    notificationOnSuccess?: string
  }) => Promise<void>
  getChipDeleteButtonClickHandler?: (
    itemId: number,
    discountId: number
  ) => () => Promise<void>
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    height: '100%',
    gridTemplateColumns: '360px 1fr'
  },
  supplement: {
    background: theme.palette.background.paper,
    borderRight: `solid ${theme.palette.divider} 1px`,
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    overflow: 'hidden'
  },
  listDivider: {
    margin: theme.spacing(0, 2)
  },
  listItem: {
    padding: theme.spacing(1, 2)
  },
  massCheckbox: {
    paddingLeft: theme.spacing(2),
    minHeight: 56,
    borderBottom: `solid ${theme.palette.divider} 1px`
  },
  hiddenCheckbox: {
    visibility: 'hidden'
  },
  discountsList: {
    padding: theme.spacing(2, 3, 11, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    overflow: 'auto'
  },
  chipLabel: {
    maxWidth: 184,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}))

export const Content: React.FC<IContentProps> = ({
  ticketItems,
  discounts,
  eventId,
  onAddButtonClick,
  incrementDiscountQuantity,
  decrementDiscountQuantity,
  getChipDeleteButtonClickHandler
}: IContentProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {currentCartId} = useCurrentCart()
  const {setShowBackdrop} = useMutationAssistanceHooks()
  const formatDiscountValue = useFormatDiscountValue(true)
  const ticketItemsWithAllowedDiscounts = ticketItems.filter(
    (item) => item.eventPricingToTicketType.promotions
  )
  const idsOfTicketItemsWithAllowedDiscounts =
    ticketItemsWithAllowedDiscounts.map(({id}) => id)
  const selectableTicketItemsIds = ticketItemsWithAllowedDiscounts
    .filter((item) => item.appliedDiscounts.length === 0)
    .map(({id}) => id)
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([])
  const [selectCustomerDialogState, setSelectCustomerDialogState] =
    useState<ISelectCustomerDialogState>(defaultSelectCustomerDialogState)
  const checkboxesControllerState =
    selectedItemIds.length === 0
      ? MassCheckboxState.Unselected
      : difference(selectableTicketItemsIds, selectedItemIds).length === 0
      ? MassCheckboxState.Selected
      : MassCheckboxState.Indeterminate
  const createItemCheckboxClickHandler = useCallback(
    (ticketId: number) => (e: React.MouseEvent) => {
      e.stopPropagation()
      setSelectedItemIds((ids) =>
        ids.includes(ticketId)
          ? ids.filter((id) => id !== ticketId)
          : [...ids, ticketId]
      )
    },
    []
  )
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()

  const {reservationId} = useReservationPathnameParams()

  const [code, setCode] = useState<string>('')
  const [validCodes, setValidCodes] = useState<
    BroadDiscountCodePropertiesFragment[]
  >([])
  const [discountCodeError, setDiscountCodeError] =
    useState<ApolloError | null>(null)

  const handleOnSuccessCodeCheck = useCallback(
    (discountCode: BroadDiscountCodePropertiesFragment) => {
      setValidCodes((validCodes) => [discountCode, ...validCodes])
      setCode('')
    },
    []
  )

  const handleOnConfirmDiscountCodeErrorButtonClick = useCallback(() => {
    setDiscountCodeError(null)
    setCode('')
  }, [])

  const [
    checkDiscountCodeForCart,
    {loading: isLoadingCheckDiscountCodeForCart}
  ] = useLazyQuery<
    CheckDiscountCodeForCartQuery,
    CheckDiscountCodeForCartQueryVariables
  >(CHECK_DISCOUNT_CODE_FOR_CART, {
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      handleOnSuccessCodeCheck(data.checkDiscountCodeForCart),
    onError: setDiscountCodeError
  })

  const [
    checkDiscountCodeForReservation,
    {loading: isLoadingCheckDiscountCodeForReservation}
  ] = useLazyQuery<
    CheckDiscountCodeForReservationQuery,
    CheckDiscountCodeForReservationQueryVariables
  >(CHECK_DISCOUNT_CODE_FOR_RESERVATION, {
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      handleOnSuccessCodeCheck(data.checkDiscountCodeForReservation),
    onError: setDiscountCodeError
  })

  const [getDiscountCode, {loading: isLoadingGetDiscountCode}] = useLazyQuery<
    DiscountCodeQuery,
    DiscountCodeQueryVariables
  >(DISCOUNT_CODE, {
    fetchPolicy: 'network-only',
    onCompleted: ({discountCode}) =>
      setValidCodes((validCodes: BroadDiscountCodePropertiesFragment[]) =>
        validCodes.reduce<BroadDiscountCodePropertiesFragment[]>(
          (acc, vc) => [...acc, vc.id === discountCode.id ? discountCode : vc],
          []
        )
      ),
    onError: setDiscountCodeError
  })

  useEffect(() => {
    if (
      isLoadingCheckDiscountCodeForCart ||
      isLoadingCheckDiscountCodeForReservation ||
      isLoadingGetDiscountCode
    ) {
      setShowBackdrop(true)
    } else {
      setShowBackdrop(false)
    }
  }, [
    isLoadingCheckDiscountCodeForCart,
    isLoadingCheckDiscountCodeForReservation,
    isLoadingGetDiscountCode,
    setShowBackdrop
  ])

  const [quantityDialogDiscount, setQuantityDialogDiscount] =
    useState<{
      discount: EnabledDiscountPropertiesFragment
      discountCode?: BroadDiscountCodePropertiesFragment
    } | null>(null)

  const updateValidCode = useCallback(
    (discountCodeId: number) => {
      const codeInList = validCodes.find((vc) => vc.id === discountCodeId)
      if (codeInList) {
        getDiscountCode({variables: {id: codeInList.id}})
      }
    },
    [getDiscountCode, validCodes]
  )
  const getQuantityControllerClickHandler = useCallback(
    (
        discount: EnabledDiscountPropertiesFragment,
        discountCode?: BroadDiscountCodePropertiesFragment
      ) =>
      () => {
        setQuantityDialogDiscount({discount, discountCode})
      },
    []
  )
  const handleQuantityDialogDiscountClose = useCallback(() => {
    setQuantityDialogDiscount(null)
  }, [])
  const getAddButtonClickHandler = useCallback(
    (discountId: number, discountCodeId?: number) => async () => {
      if (onAddButtonClick) {
        await onAddButtonClick({discountId, selectedItemIds, discountCodeId})
        setSelectedItemIds([])
        if (discountCodeId) {
          await updateValidCode(discountCodeId)
        }
      }
    },
    [onAddButtonClick, selectedItemIds, updateValidCode]
  )
  const getAddCustomerDiscountButtonClickHandler = useCallback(
    (discountId: number, type: ISelectCustomerDialogState['type']) => () => {
      setSelectCustomerDialogState({
        isOpen: true,
        discountId,
        type
      })
    },
    []
  )
  const getIncrementButtonClickHandler = useCallback(
    (discountId: number, discountCodeId?: number) => async () => {
      if (incrementDiscountQuantity) {
        await incrementDiscountQuantity({discountId, discountCodeId})
        if (discountCodeId) {
          await updateValidCode(discountCodeId)
        }
      }
    },
    [incrementDiscountQuantity, updateValidCode]
  )
  const getDecrementButtonClickHandler = useCallback(
    (discountId: number, discountCodeId?: number) => async () => {
      if (decrementDiscountQuantity) {
        await decrementDiscountQuantity({discountId, discountCodeId})
        if (discountCodeId) {
          await updateValidCode(discountCodeId)
        }
      }
    },
    [decrementDiscountQuantity, updateValidCode]
  )

  const discountCodes = discounts.filter(
    (discount) => discount.application === DiscountApplication.Code
  )

  const handleOnCheckButtonClick = useCallback(() => {
    if (currentCartId && !reservationId) {
      checkDiscountCodeForCart({
        variables: {
          cartId: currentCartId,
          eventId,
          codeName: code
        }
      })
    }
    if (reservationId) {
      checkDiscountCodeForReservation({
        variables: {reservationId, codeName: code}
      })
    }
  }, [
    checkDiscountCodeForCart,
    checkDiscountCodeForReservation,
    code,
    currentCartId,
    eventId,
    reservationId
  ])

  const handleCodeInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCode(e.target.value.toUpperCase())
    },
    []
  )
  const hasPermissionsForShowDiscountQuantityController =
    (reservationId &&
      P([
        PermissionCode.IncrementDiscountQuantityForReservationTicketItems,
        PermissionCode.DecrementDiscountQuantityFromReservationTicketItems
      ])) ||
    (currentCartId &&
      P([
        PermissionCode.IncrementDiscountQuantityForCartTicketItems,
        PermissionCode.DecrementDiscountQuantityFromCartTicketItems
      ]))

  const handleCancelButtonClick = useCallback(() => {
    setCode('')
  }, [])
  const closeSelectCustomerDialog = useCallback(
    () => setSelectCustomerDialogState(defaultSelectCustomerDialogState),
    []
  )
  const handleSelectLoyaltyId = useCallback(
    async (formData: ISelectCustomerForm) => {
      if (
        selectCustomerDialogState.discountId &&
        selectCustomerDialogState.type
      ) {
        if (selectCustomerDialogState.type === 'add' && onAddButtonClick) {
          await onAddButtonClick({
            discountId: selectCustomerDialogState.discountId,
            selectedItemIds,
            customerLoyaltyId: formData[SelectCustomerFormField.LoyaltyId],
            onSuccessCallback: () => {
              setSelectedItemIds([])
              closeSelectCustomerDialog()
            }
          })
        }
        if (
          selectCustomerDialogState.type === 'increment' &&
          incrementDiscountQuantity
        ) {
          await incrementDiscountQuantity({
            discountId: selectCustomerDialogState.discountId,
            customerLoyaltyId: formData[SelectCustomerFormField.LoyaltyId],
            onSuccessCallback: () => {
              setSelectedItemIds([])
              closeSelectCustomerDialog()
            }
          })
        }
      }
    },
    [
      incrementDiscountQuantity,
      onAddButtonClick,
      selectCustomerDialogState.discountId,
      selectCustomerDialogState.type,
      selectedItemIds,
      closeSelectCustomerDialog
    ]
  )
  const handleScanComplete = useCallback(
    async (code) => {
      await handleSelectLoyaltyId({[SelectCustomerFormField.LoyaltyId]: code})
    },
    [handleSelectLoyaltyId]
  )
  const handleRemoveDiscountClick = useCallback(
    (itemId: number, discountId: number, discountCodeId?: number) => () => {
      if (getChipDeleteButtonClickHandler) {
        getChipDeleteButtonClickHandler(itemId, discountId)()
        if (discountCodeId) {
          updateValidCode(discountCodeId)
        }
      }
    },
    [getChipDeleteButtonClickHandler, updateValidCode]
  )
  return (
    <div className={classes.root}>
      <div className={classes.supplement}>
        {(reservationId &&
          P([PermissionCode.AddDiscountToReservationTicketItems])) ||
        (currentCartId && P([PermissionCode.AddDiscountToCartTicketItems])) ? (
          <MassCheckbox
            state={checkboxesControllerState}
            className={classes.massCheckbox}
            onClick={() =>
              checkboxesControllerState === MassCheckboxState.Unselected
                ? setSelectedItemIds(selectableTicketItemsIds)
                : setSelectedItemIds([])
            }
          />
        ) : (
          <div />
        )}
        <ListOfItemsSeparatedByDividers
          DividerProps={{
            className: classes.listDivider
          }}
        >
          {ticketItems.map((ticketItem) => {
            const hasPricingWithAllowedDiscounts =
              idsOfTicketItemsWithAllowedDiscounts.includes(ticketItem.id)
            const hasAppliedDiscount = ticketItem.appliedDiscounts.length > 0
            return (
              <Item
                key={ticketItem.id}
                checkboxProps={{
                  checked: selectedItemIds.includes(ticketItem.id),
                  className:
                    hasPricingWithAllowedDiscounts &&
                    ((reservationId &&
                      P([
                        PermissionCode.AddDiscountToReservationTicketItems
                      ])) ||
                      (currentCartId &&
                        P([PermissionCode.AddDiscountToCartTicketItems])))
                      ? undefined
                      : classes.hiddenCheckbox,
                  disabled: hasAppliedDiscount
                }}
                isDisabled={!hasPricingWithAllowedDiscounts}
                onClick={
                  hasPricingWithAllowedDiscounts && !hasAppliedDiscount
                    ? createItemCheckboxClickHandler(ticketItem.id)
                    : undefined
                }
                className={classes.listItem}
                primaryLabel={getTicketListItemPrimaryLabel(
                  ticketItem.eventSeat
                )}
                priceLabel={translateEffectiveClientPrice(ticketItem.price)}
                priceBeforeDiscountLabel={
                  ticketItem.priceBeforeDiscount !== ticketItem.price
                    ? translateEffectiveClientPrice(
                        ticketItem.priceBeforeDiscount
                      )
                    : undefined
                }
              >
                {!hasPricingWithAllowedDiscounts && (
                  <Typography variant="caption">
                    {t("{{name}} can't be discounted", {
                      name: ticketItem.eventPricingToTicketType.name
                    })}
                  </Typography>
                )}
                {hasAppliedDiscount &&
                  ticketItem.appliedDiscounts.map((appliedDiscount) =>
                    (reservationId &&
                      P([
                        PermissionCode.RemoveDiscountFromReservationTicketItem
                      ])) ||
                    (currentCartId &&
                      P([PermissionCode.RemoveDiscountFromItem])) ? (
                      <Chip
                        classes={{
                          label: classes.chipLabel
                        }}
                        size="medium"
                        onDelete={handleRemoveDiscountClick(
                          ticketItem.id,
                          appliedDiscount.discount.id,
                          appliedDiscount.discountCode?.id
                        )}
                        label={
                          <Tooltip title={appliedDiscount.discount.name}>
                            <span>{appliedDiscount.discount.name}</span>
                          </Tooltip>
                        }
                        key={appliedDiscount.discount.id}
                        deleteIcon={
                          <Tooltip title={t<string>('Remove discount')}>
                            <CancelIcon />
                          </Tooltip>
                        }
                      />
                    ) : (
                      <Chip
                        classes={{
                          label: classes.chipLabel
                        }}
                        size="medium"
                        label={
                          <Tooltip title={appliedDiscount.discount.name}>
                            <span>{appliedDiscount.discount.name}</span>
                          </Tooltip>
                        }
                        key={appliedDiscount.discount.id}
                      />
                    )
                  )}
              </Item>
            )
          })}
        </ListOfItemsSeparatedByDividers>
        <TicketsSubtotal
          items={ticketItems}
          description={t('Discounted {{discountedCount}} of {{count}} ticket', {
            count: ticketItems.length,
            discountedCount: ticketItemsWithAllowedDiscounts.filter(
              (ticketItem) => ticketItem.appliedDiscounts.length
            ).length
          })}
        />
      </div>
      {discountCodeError && (
        <DiscountCodeErrorDialog
          error={discountCodeError}
          onConfirm={handleOnConfirmDiscountCodeErrorButtonClick}
        />
      )}
      {idsOfTicketItemsWithAllowedDiscounts.length ? (
        <div className={classes.discountsList}>
          {discountCodes.length > 0 &&
            ((reservationId &&
              P([PermissionCode.ReadCheckDiscountCodeForReservation])) ||
              (currentCartId &&
                P([PermissionCode.ReadCheckDiscountCodeForCart]))) && (
              <CheckDiscountCodeItem
                code={code}
                onChange={handleCodeInputChange}
                onCheckButtonClick={handleOnCheckButtonClick}
                onCancel={handleCancelButtonClick}
                errorMessage={
                  validCodes.map((c) => c.name).includes(code)
                    ? t('Code has been already verified.')
                    : null
                }
              />
            )}
          {validCodes.filter(Boolean).map((code) => {
            const {
              remainingDiscountCodeUsageLimitPerOrder,
              canDiscountCodeBeUsed,
              canDiscountCodeBeRedeemed
            } = getRemainingDiscountCodeUsageLimitPerOrder({
              discountCode: code,
              items: ticketItemsWithAllowedDiscounts,
              selectedItemIds
            })
            const {remainingDiscountUsageLimitPerOrder, canDiscountBeUsed} =
              getRemainingDiscountUsageLimitPerOrder({
                discount: code.discount,
                items: ticketItems
              })
            return (
              <DiscountListItem
                key={code.id}
                disabled={code.state === DiscountCodeState.Invalid}
                discount={code.discount}
                description={[
                  formatDiscountValue(code.discount),
                  !isNil(code.discount.maxUsageLimitPerOrder) &&
                    t('Can be redeemed {{count}} times per order.', {
                      count: remainingDiscountUsageLimitPerOrder
                    }),
                  !isNil(code.remainingRedemptionCount)
                    ? t('{{codeName}} can be redeemed {{count}} time.', {
                        codeName: code.name,
                        count: code.remainingRedemptionCount
                      })
                    : t('{{codeName}} can be redeemed infinity times.', {
                        codeName: code.name
                      }),
                  code.usageLimitPerOrder &&
                    t('Code can be redeemed {{count}} time per order.', {
                      count: code.usageLimitPerOrder
                    }),
                  code.discount.internalDescription
                ]
                  .filter(Boolean)
                  .join(' • ')}
              >
                {selectedItemIds.length &&
                ((reservationId &&
                  P([PermissionCode.AddDiscountToReservationTicketItems])) ||
                  (currentCartId &&
                    P([PermissionCode.AddDiscountToCartTicketItems]))) ? (
                  <Button
                    color="primary"
                    onClick={getAddButtonClickHandler(
                      code.discount.id,
                      code.id
                    )}
                    disabled={
                      !canDiscountCodeBeRedeemed ||
                      (!!remainingDiscountCodeUsageLimitPerOrder &&
                        selectedItemIds.length >
                          remainingDiscountCodeUsageLimitPerOrder) ||
                      (!!remainingDiscountUsageLimitPerOrder &&
                        selectedItemIds.length >
                          remainingDiscountUsageLimitPerOrder) ||
                      !canDiscountCodeBeUsed ||
                      !canDiscountBeUsed
                    }
                  >
                    {t('Add')}
                  </Button>
                ) : (
                  hasPermissionsForShowDiscountQuantityController && (
                    <QuantityController
                      quantity={getQuantityOfItemsWithAppliedDiscountCode(
                        ticketItemsWithAllowedDiscounts,
                        code
                      )}
                      maxQuantity={getMaxQuantityOfItemsForDiscountCode(
                        ticketItemsWithAllowedDiscounts,
                        code
                      )}
                      DecrementButtonProps={{
                        onClick: getDecrementButtonClickHandler(
                          code.discount.id,
                          code.id
                        )
                      }}
                      IncrementButtonProps={{
                        onClick: getIncrementButtonClickHandler(
                          code.discount.id,
                          code.id
                        ),
                        disabled: getIsIncrementButtonDisabled(
                          ticketItemsWithAllowedDiscounts,
                          getMaxQuantityOfItemsForDiscountCode(
                            ticketItemsWithAllowedDiscounts,
                            code
                          ),
                          code
                        )
                      }}
                      onClick={getQuantityControllerClickHandler(
                        code.discount,
                        code
                      )}
                    />
                  )
                )}
              </DiscountListItem>
            )
          })}
          {discounts
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter(
              (discount) => discount.application !== DiscountApplication.Code
            )
            .map((discount) => {
              const {remainingDiscountUsageLimitPerOrder, canDiscountBeUsed} =
                getRemainingDiscountUsageLimitPerOrder({
                  discount,
                  items: ticketItems
                })
              return (
                <DiscountListItem
                  key={discount.id}
                  discount={discount}
                  description={[
                    formatDiscountValue(discount),
                    [
                      !isNil(discount.maxUsageLimitPerOrder) &&
                        t('Can be redeemed {{count}} times per order.', {
                          count: remainingDiscountUsageLimitPerOrder
                        }),
                      discount.internalDescription
                    ]
                      .filter(Boolean)
                      .join(' ')
                  ]
                    .filter(Boolean)
                    .join(' • ')}
                >
                  {selectedItemIds.length &&
                  ((reservationId &&
                    P([PermissionCode.AddDiscountToReservationTicketItems])) ||
                    (currentCartId &&
                      P([PermissionCode.AddDiscountToCartTicketItems]))) ? (
                    <>
                      {discount.application === DiscountApplication.Customer ? (
                        <Button
                          color="primary"
                          onClick={getAddCustomerDiscountButtonClickHandler(
                            discount.id,
                            'add'
                          )}
                          disabled={
                            (!!remainingDiscountUsageLimitPerOrder &&
                              selectedItemIds.length >
                                remainingDiscountUsageLimitPerOrder) ||
                            !canDiscountBeUsed
                          }
                        >
                          {t('Add')}
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          onClick={getAddButtonClickHandler(discount.id)}
                          disabled={
                            (!!remainingDiscountUsageLimitPerOrder &&
                              selectedItemIds.length >
                                remainingDiscountUsageLimitPerOrder) ||
                            !canDiscountBeUsed
                          }
                        >
                          {t('Add')}
                        </Button>
                      )}
                    </>
                  ) : (
                    hasPermissionsForShowDiscountQuantityController && (
                      <QuantityController
                        quantity={getQuantityOfItemsWithAppliedDiscount(
                          ticketItemsWithAllowedDiscounts,
                          discount
                        )}
                        maxQuantity={getMaxQuantityOfItemsForDiscount(
                          ticketItemsWithAllowedDiscounts,
                          discount
                        )}
                        DecrementButtonProps={{
                          onClick: getDecrementButtonClickHandler(discount.id)
                        }}
                        IncrementButtonProps={{
                          onClick:
                            discount.application ===
                            DiscountApplication.Customer
                              ? getAddCustomerDiscountButtonClickHandler(
                                  discount.id,
                                  'increment'
                                )
                              : getIncrementButtonClickHandler(discount.id)
                        }}
                        onClick={
                          discount.application === DiscountApplication.Customer
                            ? getAddCustomerDiscountButtonClickHandler(
                                discount.id,
                                'increment'
                              )
                            : getQuantityControllerClickHandler(discount)
                        }
                      />
                    )
                  )}
                </DiscountListItem>
              )
            })}
          {quantityDialogDiscount && (
            <EditDiscountQuantityDialog
              maxQuantity={
                quantityDialogDiscount.discountCode
                  ? getMaxQuantityOfItemsForDiscountCode(
                      ticketItemsWithAllowedDiscounts,
                      quantityDialogDiscount.discountCode
                    )
                  : getMaxQuantityOfItemsForDiscount(
                      ticketItemsWithAllowedDiscounts,
                      quantityDialogDiscount.discount
                    )
              }
              initialQuantity={
                quantityDialogDiscount.discountCode
                  ? getQuantityOfItemsWithAppliedDiscountCode(
                      ticketItemsWithAllowedDiscounts,
                      quantityDialogDiscount.discountCode
                    )
                  : getQuantityOfItemsWithAppliedDiscount(
                      ticketItemsWithAllowedDiscounts,
                      quantityDialogDiscount.discount
                    )
              }
              onClose={handleQuantityDialogDiscountClose}
              discount={quantityDialogDiscount.discount}
              discountCodeId={quantityDialogDiscount.discountCode?.id}
              incrementDiscountQuantity={incrementDiscountQuantity}
              decrementDiscountQuantity={decrementDiscountQuantity}
              onDiscountWithCodeQuantityChange={updateValidCode}
              remainingDiscountUsageLimitPerOrder={getRemainingDiscountUsageLimitPerOrderForDiscountDialog(
                {
                  discount: quantityDialogDiscount.discount,
                  items: ticketItems
                }
              )}
            />
          )}
        </div>
      ) : (
        <NoDiscountableItemsBlank eventId={eventId} />
      )}
      <SelectCustomerDialog
        isOpen={selectCustomerDialogState.isOpen}
        onClose={closeSelectCustomerDialog}
        onSubmit={handleSelectLoyaltyId}
        onScanComplete={handleScanComplete}
      />
    </div>
  )
}
